@import "assets/styles/bulma";

.variation {
  border-radius: 4px;
  min-width: 93px;

  &.has-background-light-light {
    background-color: #ececec;
  }
}
