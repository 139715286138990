@import 'assets/styles/bulma';

.flag-button {
  border-radius: 50%;;
  cursor: pointer;
  text-decoration: none !important;
  padding: 1.7rem;

  &:hover {
    background-color: #e8e8e8;
  }

  &:focus {
    box-shadow: none !important;
  }
}
