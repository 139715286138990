@import 'assets/styles/bulma';

.errorscreen-container {
  height: 100%;
  width: 100%;
}

.errorscreen-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.errorscreen-label {
  margin-top: 5px;
  font-size: 22px;
}

.errorscreen-cross {
  width: 50px;
  height: 50px;
}
