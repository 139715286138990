@import 'assets/styles/bulma';

.method-label {
  color: white !important;
  &.get {
    background-color: $orwell-get-method-color;
  }
  &.post {
    background-color: $orwell-post-method-color;
  }
  &.put {
    background-color: $orwell-put-method-color;
  }
  &.patch {
    background-color: $orwell-patch-method-color;
  }
  &.delete {
    background-color: $orwell-delete-method-color;
  }
  &.head {
    background-color: $orwell-head-method-color;
  }
  &.connect {
    background-color: $orwell-connect-method-color;
  }
  &.options {
    background-color: $orwell-options-method-color;
  }
  &.trace {
    background-color: $orwell-trace-method-color;
  }
}
