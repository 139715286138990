@import "assets/styles/bulma";

.drawer {
  position: fixed;
  height: 100vh;
  top: 0;
  z-index: $orwell-drawer-z-index;
  transition: right 250ms ease-in-out;
  height: 100vh;
  border-radius: 4px 0px 0px 4px;
  background-color: white;

  &.is-small {
    right: -$orwell-drawer-small-width;
    min-width: $orwell-drawer-small-width;
    width: $orwell-drawer-small-width;
  }

  &.is-medium {
    right: -$orwell-drawer-medium-width;
    min-width: $orwell-drawer-medium-width;
    width: $orwell-drawer-medium-width;
  }

  &.is-large {
    right: -$orwell-drawer-large-width;
    min-width: $orwell-drawer-large-width;
    width: $orwell-drawer-large-width;
  }

  &.is-open {
    right: 0;
  }

  .drawer-header {
    display: block;
    height: 20px;
  }

  @media (max-width: $tablet) {
    right: -90vw !important;
    min-width: 90vw !important;
    width: 90vw !important;

    &.is-open {
      right: 0 !important;
    }
  }

  .drawer-loading-error {
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    color: white;

    .content {
      width: 64px;
      height: 64px;
    }
  }
}

.drawer-cover {
  z-index: $orwell-drawer-cover-z-index;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease-in-out;

  &.is-open {
    opacity: 0.7;
    pointer-events: unset;
  }
}
