@import "assets/styles/bulma";

.icon {
  &.is-larger {
    height: 4rem;
    width: 4rem;
  }

  &.is-huge {
    height: 5rem;
    width: 5rem;
  }
}
