@import "assets/styles/bulma";

.my-container {
  overflow: scroll;
  min-width: 1300px;
}

.my-table {
  border-radius: 5px !important;
  border-collapse: separate !important;
  table-layout: fixed;
  font-size:small;
}

.table-uri-data-field {
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
}

.table-ip-data-field {
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
}

.table-uri-header-field {
  width: 300px;
}

.table-label-header-field {
  width: 70px;
}

.table-method-header-field {
  width: 150px;
}
