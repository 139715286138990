@import 'assets/styles/bulma';

.layout {
  height: 100vh;
  display: flex;
}

.layout-content-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.layout-content {
  margin: 20px;
  transition: margin-left 350ms ease-in-out;
  margin-top: $orwell-navbar-height + 20px;
  
  &.with-sidebar {
    margin-left: $orwell-sidebar-width + 20px;
  }
  
  @media (max-width: $tablet) {
    margin-left: 20px !important;
  }
}
