@import "assets/styles/bulma";

.container-wrapper {
  .container {
    padding: 16px;
    padding-top: 64px;
    border: 5px solid white;
    text-align: center;
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: column;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));

    .add-btn {
      cursor: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      font-size: 96px;
      color: white;
      overflow: hidden;
      transition: color 100ms;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));

      &:hover {
        color: $orwell-light-font-color;
      }
    }

    p {
      width: 200px;
    }
  }
}
