@import "assets/styles/bulma";

.divider {
  background-color: lighten($orwell-separator, 40);
}

.vertical-divider {
  width: 1px;
}

.horizontal-divider {
  height: 1px;
}
