@import 'assets/styles/bulma';

.loggerbar {
  min-width:$orwell-navbar-height;
  width: $orwell-navbar-height;
  height: calc(100vh - #{$orwell-navbar-height});
  background: $orwell-dark-alpha;
  border-right: 1px solid darken($orwell-separator, 10);
  padding-top: 20px;
  overflow-y: auto !important;

  .logger-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logger-btn .add-btn {
      cursor: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 48px;
      min-height: 48px;
      width: 48px;
      height: 48px;
      color: $orwell-red;
      font-size: 48px;
      border-radius: 3px;
      border: 2px solid $orwell-red;
      overflow: hidden;
      transition: background-color 100ms;

      &:hover {
        background-color: $orwell-dark;
      }
    }
  }
}
