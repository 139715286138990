@import "assets/styles/bulma";

.card {
  min-width: 100px;
  min-height: 100px;
  background: #ffffff;
  width: 100%;
  height: 100%;

  .card-content {
    padding: 16px;
    width: 100%;
    height: 100%;
  }
}
