@import 'assets/styles/bulma';

.orwell-title {
  
  &.dark {
    color: $orwell-light-font-color;
  }

  &.light {
    color: $orwell-dark-font-color;
  }
}
