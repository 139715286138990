@import "assets/styles/bulma";

.color-picker {
  width: 100%;

  .color-button {
    border-radius: 50%;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 34px;
    cursor: pointer;

    &:hover {
      border: lighten($orwell-dark-background, 50%) solid 2px;
    }

    &.selected {
      border: $orwell-dark-background solid 2px;
    }

    .color-button-bubble {
      display: inline-block;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
  }
}
