@import "assets/styles/bulma";

.pagination-controller {
  background-color: $orwell-red;

  i {
    color: white;
  }
}

.page-limit {
  opacity: 50%;
}
