@import 'assets/styles/bulma';

.sidebarmenu-wrap {
  width: 100%;
  border-bottom: 1px solid darken($orwell-separator, 10);
  padding-bottom: 5px;
  box-sizing: border-box;
}

.sidebarmenu-title {
  width: 100%;
  font-size: 13px;
  padding-left: $orwell-sidebar-menu-padding;
  padding-right: $orwell-sidebar-menu-padding;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  color: $orwell-subtitle-color;
}

.sidebarmenu-links-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sidebarmenu-link {
  width: 100%;
  font-size: 15px;
  color: $orwell-light-font-color;
  padding-left: $orwell-sidebar-menu-padding;
  padding-right: $orwell-sidebar-menu-padding;
  padding-top: 3px;
  padding-bottom: 3px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
    color: $orwell-light-font-color;
  }

  &.selected {
    background-color: rgba(255, 255, 255, 0.16);
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
