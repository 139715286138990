// Set your brand colors
$orwell-red: #b0413e;
$orwell-red-alpha: #923532;
$orwell-dark: #22333b;
$orwell-dark-alpha: #18272F;
$orwell-info: #779ecb;
$orwell-warning: #ffc857;
$orwell-success: #26AA6C;
$orwell-danger: #c23b22;
$orwell-separator: #676767;
$orwell-table-separator: #dbdbdb;
// font colors
$orwell-dark-font-color: #22333b;
$orwell-light-font-color: #bdbdbd;
$orwell-subtitle-color: #828282;
// method colors
$orwell-get-method-color: #26AA6C;
$orwell-post-method-color: #FF9F40;
$orwell-put-method-color: #9966FF;
$orwell-patch-method-color: #36A2EB;
$orwell-delete-method-color: #DF2B2B;
$orwell-head-method-color: #4BC0C0;
$orwell-connect-method-color: #FFCD56;
$orwell-options-method-color: #43AA8B;
$orwell-trace-method-color: #577590;
// background
$orwell-light-background: #efefef;
$orwell-dark-background: #22333b;
// z-indexes
$orwell-sidebar-cover-zindex: 90;
$orwell-navbar-zindex: 50;
// sizes
$orwell-navbar-height: 84px;
$orwell-sidebar-width: 320px;
$orwell-sidebar-menu-padding: 15px;
$orwell-sidebar-hamburguer-size: 24px;
// drawer
$orwell-drawer-small-width: 320px;
$orwell-drawer-medium-width: 420px;
$orwell-drawer-large-width: 520px;
$orwell-drawer-z-index: 200;
$orwell-drawer-cover-z-index: 190;
// notifications/toast
$orwell-notifications-z-index: 300;
