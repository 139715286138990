@import 'assets/styles/bulma';

.logger-btn {
  margin-bottom: 20px;

  .wrap {
    cursor: pointer;
    border-radius: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
    box-sizing: border-box;

    &.selected {
      margin-top: 0px;
      margin-bottom: 0px;
      border: 2px solid white;
    }
  }

  .btn {
    cursor: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 48px;
    min-height: 48px;
    width: 48px;
    height: 48px;
    background-color: $orwell-red;
    color: white;
    font-size: 48px;
    border-radius: 3px;
    overflow: hidden;
    transition: background-color 100ms;

    &:hover {
      background-color: $orwell-red-alpha;
    }

    &.large {
      width: 200px;
      height: 200px;
      font-size: 96px;
    }
  }

  .notification-badge {
    position: absolute;
    pointer-events: none;

    .badge {
      position: relative;
      bottom: 6px;
      left: 36px;
      background-color: red;
      color: white;
      font-size: 13px;
      border-radius: 20px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.selected {
        left: 38px;
      }

      &.large {
        left: 188px;
      }

    }
  }
}
