@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
// Import brand variables
@import '_variables.scss';
// import initial variables
@import 'bulma/sass/utilities/initial-variables.sass';
// Update Bulma's global variables
// font
$family-sans-serif: 'Roboto', sans-serif;
// colors
$primary: $orwell-red;
$info: $orwell-info;
$success: $orwell-success;
$warning: $orwell-warning;
$danger: $orwell-danger;
$dark: $orwell-dark;
$body-background-color: $orwell-light-background;

// text
$text: $orwell-dark-font-color;
$light: $orwell-light-font-color;

// Update some of Bulma's component variables
$pagination-current-background-color: $primary;
$pagination-current-border-color: $primary;
$pagination-border-color: $primary;
$pagination-color: $primary;
$pagination-ellipsis-color: $black;
$table-cell-border: 2px solid $orwell-table-separator;
$dropdown-item-hover-background-color: hsla(2,64.8%,69.0%,0.5);
// forms
$input-focus-border-color: $orwell-separator;
// table
$table-background-color: transparent;
