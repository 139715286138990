@import 'assets/styles/bulma';

.navbar {
  position: fixed;
  width: 100%;
  min-height: $orwell-navbar-height;
  height: $orwell-navbar-height;
  border-bottom: 1px solid lighten($orwell-separator, 40);
  background: #F6F6F6;
  padding: 0em 2em;
  z-index: $orwell-navbar-zindex;
}
