@import 'assets/styles/bulma';

.sidebar {
  position: fixed;
  min-width: $orwell-sidebar-width;
  width: $orwell-sidebar-width;
  height: 100%;
  z-index: 100;
  background: $orwell-dark;
  left: 0px;
  transition: left 350ms ease-in-out;

  @media (max-width: $tablet) {
    left: - $orwell-sidebar-width;

    &.opened {
      left: 0px;
    }
  }
}

.sidebar-header {
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid darken($orwell-separator, 10);
}

.sidebar-hamburguer {
  height: $orwell-sidebar-hamburguer-size;
  width: $orwell-sidebar-hamburguer-size;
  position: fixed;
  top: calc(#{$orwell-navbar-height} / 2 - #{$orwell-sidebar-hamburguer-size});
  left: 10px;
  cursor: pointer;
  display: none;
  z-index: $orwell-sidebar-cover-zindex;

  @media (max-width: $tablet) {
    display: block;
  }
}

.sidebar-split {
  display: flex;
}

.sidebar-content {
  width: 100%;
  color: $orwell-light-font-color;
}

.sidebar-title {
  width: 100%;
  font-size: 18px;
  padding: $orwell-sidebar-menu-padding;
  padding-bottom: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid darken($orwell-separator, 10);
}

.sidebar-cover {
  z-index: $orwell-sidebar-cover-zindex;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0;
  pointer-events: none;
  transition: opacity 350ms ease-in-out;

  @media (max-width: $tablet) {
    &.opened {
      opacity: 0.7;
      pointer-events: unset;
    }
  }
}
