.activity-filter-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.activity-filter-btn {
  margin-left: 5px;
}
