@import "assets/styles/bulma";

.create-logger {

  &.container-wrapper {
    max-width: 600px;
    margin: auto;

    .container {
      padding: 16px;
      border: 1px solid white;
      display: flex;
      height: 100%;
      flex-direction: column;
    }

    input, textarea {
      background-color: $orwell-light-background;
    }

    textarea {
      resize: none;
    }

  }

  .character-counter {
    font-size: 0.8em;
  }
}

/*
.logger-btn {
  margin-bottom: 0px !important;

  .btn {
    width: 150px !important;
    height: 150px !important;
    font-size: 80px !important;
  }
}
*/
