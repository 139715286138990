@import 'assets/styles/bulma';

.toast-container {
  box-sizing: border-box;
  position: fixed;
  z-index: $orwell-notifications-z-index;

  .toast {
    width: 360px;
  }
}

.top-right {
  top: 12px;
  right: 12px;

  .toast {
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
  }
}

.bottom-right {
  bottom: 12px;
  right: 12px;

  .toast {
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
  }
}

.top-left {
  top: 12px;
  left: 12px;

  .toast {
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
  }
}

.bottom-left {
  bottom: 12px;
  left: 12px;

  .toast {
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
  }
}

@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes toast-in-left {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
}


