// Fonts
@charset "utf-8";
// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');

// Bulma
// Import only what you need from Bulma
@import 'bulma';
@import 'bulma/sass/utilities/_all.sass';
@import 'bulma/sass/base/_all.sass';
// helpers
@import 'bulma/sass/helpers/_all.sass';
// elements
@import 'bulma/sass/elements/icon.sass';
@import 'bulma/sass/elements/button.sass';
@import 'bulma/sass/elements/image.sass';
@import 'bulma/sass/elements/table.sass';
@import 'bulma/sass/elements/title.sass';
@import 'bulma/sass/elements/tag.sass';
@import 'bulma/sass/elements/other.sass';
@import 'bulma/sass/elements/box.sass';
@import 'bulma/sass/elements/notification.sass';
@import 'bulma/sass/elements/progress.sass';
// form
@import 'bulma/sass/form/_all.sass';
// layout
@import 'bulma/sass/layout/hero.sass';
// grid and tiles
@import 'bulma/sass/grid/_all.sass';
// components
@import 'bulma/sass/components/pagination.sass';
@import 'bulma/sass/components/card.sass';
@import 'bulma/sass/components/dropdown.sass';
// grid
@import 'bulma/sass/grid/columns.sass';
// Bulma extras
@import '~bulma-tooltip';

// Fontawesome
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';

// Custom global styling
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
